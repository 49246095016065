import React from 'react';
import styled from 'styled-components';
import { FourOhFourPageContainer } from '../components/FourOhFourContainer';

export default function FourOhFourPage() {
  return (
    // TODO: add texts to sanity
    <FourOhFourPageContainer>
      <FourOhFourPageContent>
        <h1>Ops!</h1>
        <p>DNoe gikk galt...</p>
      </FourOhFourPageContent>
    </FourOhFourPageContainer>
  );
}

const FourOhFourPageContent = styled.div`
  margin: 0 auto;

  h1 {
    font-size: 2.8rem;
  }

  p {
    font-size: 2rem;
  }
`;
